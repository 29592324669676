import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/fede/source/largoplacismo/src/components/layout.js";
import TableOfContents from "../../components/table-of-contents";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className='faq-intro'>
      <p>{`Si tienes alguna pregunta acerca del largoplacismo, quizá encuentres la respuesta en esta sección. Puedes seleccionar una de las preguntas del índice de arriba para consultarla directamente.`}</p>
      <p>{`Hemos incluido algunas respuestas breves a las objeciones más habituales al largoplacismo, tanto en la teoría como en la práctica. Algunas objeciones pueden estar basadas en malentendidos comprensibles, mientras que otras plantean cuestiones importantes y difíciles a los largoplacistas. No se debería dar por sentado que todas estas preguntas tienen respuestas plenamente consensuadas. De hecho, los largoplacistas siguen debatiendo muchas de ellas sin llegar todavía a un consenso.`}</p>
      <p>{`Si tienes alguna pregunta que no se responda en esta sección, `}<a parentName="p" {...{
          "href": "https://www.finmoorhouse.com/"
        }}>{`ponte en contacto con nosotros`}</a>{`. También puedes planteársela a los miembros de la comunidad del `}<a parentName="p" {...{
          "href": "https://www.effectivealtruism.org/"
        }}>{`altruismo eficaz`}</a>{`. Para ello, solo tienes que publicarla en `}<a parentName="p" {...{
          "href": "https://forum.effectivealtruism.org/"
        }}>{`Effective Altruism Forum`}</a>{` (el foro del altruismo eficaz, solo disponible en inglés).`}</p>
    </div>
    <h2 {...{
      "id": "de-verdad-es-posible-influir-de-manera-positiva-en-el-futuro-a-largo-plazo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#de-verdad-es-posible-influir-de-manera-positiva-en-el-futuro-a-largo-plazo",
        "aria-label": "de verdad es posible influir de manera positiva en el futuro a largo plazo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿De verdad es posible influir de manera positiva en el futuro a largo plazo?`}</h2>
    <p>{`Podemos dejar huella en el futuro de forma trivial; por ejemplo, grabando nuestro nombre en piedra para que pueda verse durante miles de años. Sin embargo, esa no es la cuestión, porque no estaríamos influyendo en el futuro de manera positiva ni importante. Nuestras acciones pueden acabar, en cambio, repercutiendo en el futuro a largo plazo significativamente, pero de una forma que no hemos contemplado o que no podríamos haber contemplado. Por tanto, el hecho de que podamos prever nuestro impacto positivo en el futuro importa. En resumen, la pregunta relevante es la siguiente: ¿Es posible influir de manera positiva y predecible en el futuro a largo plazo? Es razonable plantearse si realmente podemos hacer algo ahora que vaya a dejar una huella significativa en el futuro a largo plazo.`}</p>
    <p>{`Sin embargo, también hay motivos de peso para pensar que podemos hacer cosas en el presente que influyan en el futuro a muy largo plazo.`}</p>
    <p>{`Mitigar los efectos más perjudiciales del cambio climático puede considerarse una «prueba de concepto». Más allá de los daños que provoca no gestionar el cambio climático en el corto plazo, sabemos que el dióxido de carbono puede permanecer en la atmósfera terrestre durante decenas de miles de años. También sabemos que hay maneras de reducir nuestras emisiones, como invertir en tecnología verde y poner precio a las emisiones de carbono en consonancia con su verdadero coste social. Si conseguimos satisfacer prácticamente toda la demanda energética y eléctrica del mundo sin quemar hidrocarburos, no tenemos motivos para esperar que las generaciones futuras vayan a tirar por la borda nuestro trabajo, así que nuestro éxito perduraría durante mucho tiempo.`}</p>
    <p>{`Los esfuerzos que dediquemos en el presente pueden influir en el futuro a largo plazo de forma aún más radical. El filósofo Toby Ord afirma que este siglo podría entrañar riesgos insostenibles y sin precedentes que amenazarían con truncar el potencial de la humanidad para prosperar en el futuro lejano. Los denomina «riesgos existenciales». Pero hay esperanza: Ord también sostiene que podríamos tomar decisiones que acabarían con este periodo. Si llevamos a cabo acciones deliberadas, podríamos conseguir evitar una catástrofe lo suficientemente grave como para llevar a la humanidad por un camino mucho peor o, incluso, provocar su extinción. Los riesgos más importantes de este tipo son de origen humano, como pandemias artificiales mucho peores que la de covid-19 o el advenimiento de una inteligencia artificial (IA) irreversiblemente potente que no esté alineada con los valores que consideramos buenos. Dicho esto, dado que los principales riesgos son de origen humano, está en nuestra mano reducirlos. Resulta difícil imaginar un ejemplo más claro de cómo influir de manera positiva en el futuro a largo plazo que evitar una catástrofe existencial.`}</p>
    <p>{`Si quieres profundizar en el tema, consulta el apartado `}<a parentName="p" {...{
        "href": "https://www.largoplacismo.com/introduccion#nuestras-acciones-podr%C3%ADan-influir-en-el-futuro-a-largo-plazo"
      }}>{`Nuestras acciones podrían influir en el futuro a largo plazo`}</a>{` en la sección «Introducción» de este sitio.`}</p>
    <h2 {...{
      "id": "no-son-obvios-muchos-de-los-argumentos-del-largoplacismo-por-qué-la-gente-está-empezando-a-darse-cuenta-de-esto-ahora",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#no-son-obvios-muchos-de-los-argumentos-del-largoplacismo-por-qu%C3%A9-la-gente-est%C3%A1-empezando-a-darse-cuenta-de-esto-ahora",
        "aria-label": "no son obvios muchos de los argumentos del largoplacismo por qué la gente está empezando a darse cuenta de esto ahora permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿No son obvios muchos de los argumentos del largoplacismo? ¿Por qué la gente está empezando a darse cuenta de esto ahora?`}</h2>
    <p>{`Algunas características de la visión del mundo largoplacista no son particularmente nuevas ni sofisticadas, ni tampoco difíciles de entender. Por ejemplo, no resulta controvertido afirmar que al menos algunas personas que vivan en el futuro importan: quienes están a las puertas de ser padres actúan de forma perfectamente razonable cuando hacen los preparativos necesarios para asegurarse de que el bebé que va a nacer tenga una buena vida. Del mismo modo, tampoco parece controvertido decir que las personas importan aunque no estén estrechamente relacionadas con nosotros. Es decir, que podríamos ampliar a ellas la preocupación que sentimos por nuestra futura familia.`}</p>
    <p>{`Esto nos lleva a plantearnos por qué no se abraza ni se sigue el largoplacismo de manera más amplia y por qué solo ahora empieza a tomarse en serio como proyecto intelectual.`}</p>
    <p>{`Una posible respuesta es que para concebir una idea completa del largoplacismo ha sido necesaria una serie de descubrimientos recientes y sorprendentes. Por ejemplo, gracias a diversos hallazgos geológicos y cosmológicos comenzamos a ser plenamente conscientes del tiempo que nos queda en la Tierra y fuera de ella. Hace muy poco que empezamos a darnos cuenta de la persistencia de los efectos de nuestras actividades a lo largo del tiempo: `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Historia_de_la_ciencia_del_cambio_clim%C3%A1tico%23Comienza_a_lograrse_un_consenso:_1980-1988"
      }}>{`fue a finales`}</a>{` del siglo XX, cuando los climatólogos llegaron a un consenso sobre el hecho de que los gases de efecto invernadero originados por los humanos pueden permanecer en la atmósfera durante decenas de miles de años.`}</p>
    <p>{`Si bien existe la noción de que las personas que vivan en el futuro importan, fue necesario que se idearan conceptos pioneros para comprender qué tipos de obligaciones éticas podemos tener con las personas futuras. Antes de que se colonizara Norteamérica, los `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Iroqu%C3%A9s"
      }}>{`iroqueses`}</a>{` habían desarrollado y enseñaban el `}<a parentName="p" {...{
        "href": "https://www.ictinc.ca/blog/seventh-generation-principle"
      }}>{`principio de las siete generaciones`}</a>{`, según el cual las decisiones que se toman en el presente deben beneficiar a las próximas siete generaciones. En el pensamiento contemporáneo se reinventaron conceptos similares, como los que se explican en los libros `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Razones_y_personas"
      }}><em parentName="a">{`Razones y personas`}</em></a>{`, de Derek Parfit, y `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/The_Fate_of_the_Earth"
      }}><em parentName="a">{`El destino de la Tierra`}</em></a>{`, de Jonathan Schell. Como `}<a parentName="p" {...{
        "href": "https://mitpress.mit.edu/9781913029845/"
      }}>{`describe`}</a>{` el historiador Thomas Moynihan, las perspectivas históricas acerca del futuro de la humanidad casi nunca dejan espacio a «lo que está en juego», es decir, a la idea de que podría ser nuestra responsabilidad asegurarnos de que las cosas vayan bien. De hecho, muy pocas personas se han parado a pensar en la posibilidad de que la humanidad se extinga por accidente.`}</p>
    <p>{`Durante gran parte de la historia, no estaba claro cómo se podía influir positivamente en el futuro a largo plazo. Ni siquiera las personas preocupadas por el tema sabían cómo hacerlo. Puede que esto haya cambiado hace poco. En primer lugar, ahora contamos con tecnologías lo suficientemente potentes como para tener un impacto en nuestro futuro al completo, como es el caso de las armas nucleares. En segundo lugar, hemos logrado avances en las ciencias sociales y físicas que nos han permitido predecir de manera más precisa algunos de los efectos a largo plazo de nuestras acciones.`}</p>
    <p>{`No fue hasta la invención de las armas nucleares cuando la humanidad adquirió los medios para autodestruirse. En el horizonte hay otras tecnologías de una potencia similar, como la IA, la biotecnología avanzada y la ingeniería climática. Asegurar que estas tecnologías se desarrollen e implementen de forma segura parece una vía prometedora para mejorar el futuro en un horizonte temporal lejano, sobre todo reduciendo la probabilidad de una catástrofe existencial. Por tanto, a pesar de que la importancia abstracta de mejorar el futuro a largo plazo siempre fue obvia, ahora está empezando a parecer mucho más importante en un sentido práctico.`}</p>
    <p>{`Debemos tener en cuenta también que muchas de las perspectivas morales que hoy damos por sentadas tardaron mucho tiempo en adoptarse de manera generalizada. Durante mucho tiempo, solo algunos pensadores y activistas en los márgenes abogaron por la abolición de la esclavitud, por la ampliación del sufragio a las mujeres y por la idea de tratar a los animales humanamente. En aquellos tiempos, puede que muchos de los argumentos a favor de esas posturas morales fueran conocidos y valorados, pero durante mucho tiempo esas perspectivas siguieron resultando poco obvias.`}</p>
    <h2 {...{
      "id": "largoplacismo-no-es-simplemente-un-sinónimo-de-utilitarismo-aplicado",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#largoplacismo-no-es-simplemente-un-sin%C3%B3nimo-de-utilitarismo-aplicado",
        "aria-label": "largoplacismo no es simplemente un sinónimo de utilitarismo aplicado permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Largoplacismo no es simplemente un sinónimo de utilitarismo aplicado?`}</h2>
    <p>{`Las teorías `}<a parentName="p" {...{
        "href": "https://www.utilitarianism.net/"
      }}>{`utilitaristas`}</a>{` en el campo de la ética se centran en producir las mejores consecuencias posibles mejorando las vidas de todos los seres sintientes. Uno de sus rasgos principales es la imparcialidad. El utilitarismo sostiene que deberíamos asignar la misma consideración moral al bienestar de todos los individuos, independientemente de características como su género, raza, nacionalidad o, incluso, especie. En otras palabras, la felicidad o el sufrimiento, entre otras cosas buenas y malas, tienen la misma importancia independientemente de quién las experimente.`}</p>
    <p>{`Las teorías utilitaristas también reivindican que debemos ser conscientes de la escala de los resultados buenos y malos. Por ejemplo, un resultado que implique la felicidad (o infelicidad) del doble de personas debería considerarse el doble de bueno (o malo). Esto es importante porque sabemos que solemos caer en la `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Negligencia_de_alcance"
      }}>{`insensibilidad al alcance`}</a>{`, que podría sesgarnos a la hora de abordar problemas particularmente grandes.`}</p>
    <p>{`Puede decirse que el largoplacismo es una consecuencia lógica de la mayoría de las versiones del utilitarismo, si asumimos que algunas de nuestras acciones pueden influir de manera significativa y predecible en el futuro a largo plazo. Las teorías morales imparciales, como el utilitarismo, sugieren naturalmente que, del mismo modo que no importa, en términos morales, dónde hayamos nacido, tampoco importa cuándo hayamos nacido. Así pues, el énfasis en la imparcialidad nos exige ampliar nuestro «`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/The_Expanding_Circle"
      }}>{`círculo de preocupación`}</a>{`» moral para que abarque a las generaciones futuras.`}</p>
    <p>{`Asimismo, al centrarse en la escala, el utilitarismo valora el futuro a largo plazo de manera proporcional a su enorme alcance y duración, por lo que tiene en cuenta los billones de vidas que podrían existir.`}</p>
    <p>{`Dicho esto, no tenemos por qué creer en el utilitarismo para que nos parezca convincente el largoplacismo. Para empezar, muchas otras teorías `}<a parentName="p" {...{
        "href": "https://plato.stanford.edu/entries/consequentialism/"
      }}>{`consecuencialistas`}</a>{` (es decir, aquellas que determinan el valor de las acciones según sus efectos) que no son utilitaristas coinciden en la importancia de la imparcialidad y de la sensibilidad al alcance. Las características particulares que distinguen al utilitarismo de otras teorías, como el `}<a parentName="p" {...{
        "href": "https://es.wikipedia.org/wiki/Prioritarismo"
      }}>{`prioritarismo`}</a>{` o el `}<a parentName="p" {...{
        "href": "https://plato.stanford.edu/entries/egalitarianism/"
      }}>{`igualitarismo`}</a>{`, no tienen por qué aplicarse al largoplacismo.`}</p>
    <p>{`Asimismo, la idea de que influir de manera positiva en el futuro a largo plazo es una prioridad clave de nuestro tiempo puede encajar en muchas teorías morales que no son consecuencialistas. Por ejemplo, podemos encontrar motivos que se basen en el pasado para proteger a las generaciones futuras. Si tenemos en cuenta toda la historia de la humanidad, podemos sentir una cierta responsabilidad de continuar lo que emprendieron las generaciones pasadas. El filósofo Toby Ord apunta lo siguiente:`}</p>
    <p>{`«Dado que la flecha del tiempo hace que sea mucho más sencillo ayudar a las personas futuras que a las que nos precedieron, quizá la mejor forma de comprender el vínculo entre generaciones sea asimétricamente, con unas responsabilidades que se proyectan hacia el futuro y que dan sus frutos en el futuro. Por tanto, nuestro deber con las generaciones futuras puede que radique en el trabajo que hicieron nuestros antepasados cuando nosotros éramos esas generaciones futuras».`}</p>
    <p>{`No obstante, si bien hay muchas razones no consecuencialistas para preocuparnos por las generaciones futuras, no alcanzan a justificar que influir positivamente en el futuro a largo plazo sea una prioridad moral clave de nuestro tiempo. Es razonable pensar que ser conscientes de la posible escala del futuro (y de la repercusión que nuestras acciones pueden tener en él) y actuar en consecuencia según nuestros principios es una parte importante del largoplacismo.`}</p>
    <p>{`En cualquier caso, no hace falta comprometerse con una teoría ética en particular para adoptar una perspectiva largoplacista. Obviamente, no hay ningún problema en evaluar el largoplacismo basándonos en sus cualidades y en argumentos que se sostienen por sí mismos y que pueden interesar a las intuiciones sensatas. Del mismo modo, no hay nada malo en preocuparnos por el medioambiente sin prestar demasiada atención a qué teoría moral nos parece más plausible.`}</p>
    <p>{`Dicho esto, también merece la pena reflexionar acerca de la pregunta inicial: se da el caso de que ciertas clases de utilitarismo proponen tipos de largoplacismo particularmente radicales, como sucede con el «`}<a parentName="p" {...{
        "href": "https://www.utilitarianism.net/population-ethics#the-total-view"
      }}>{`utilitarismo total`}</a>{`», que determina el valor de los resultados sumando la cantidad total de bienestar que producen. Filósofos como Hilary Greaves y William MacAskill explican en `}<a parentName="p" {...{
        "href": "https://globalprioritiesinstitute.org/hilary-greaves-william-macaskill-the-case-for-strong-longtermism-2/"
      }}><em parentName="a">{`The Case for Strong Longtermism`}</em></a>{` (En defensa del largoplacismo radical) cómo podrían inferirse de versiones estándar del utilitarismo total versiones más radicales del largoplacismo.`}</p>
    <h2 {...{
      "id": "el-largoplacismo-implica-que-debemos-hacer-enormes-sacrificios-de-cara-al-futuro-no-es-desmesuradamente-exigente",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#el-largoplacismo-implica-que-debemos-hacer-enormes-sacrificios-de-cara-al-futuro-no-es-desmesuradamente-exigente",
        "aria-label": "el largoplacismo implica que debemos hacer enormes sacrificios de cara al futuro no es desmesuradamente exigente permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`¿El largoplacismo implica que debemos hacer enormes sacrificios de cara al futuro? ¿No es desmesuradamente exigente?`}</strong></h2>
    <p>{`Si proteger y mejorar el futuro a largo plazo es tan importante como sostiene el largoplacismo, puede que tenga sentido dejar de lado beneficios presentes a corto plazo por el bien de las generaciones futuras. Algunas personas objetan que podría no haber un límite superior en el tipo de sacrificios que el largoplacismo podría recomendar que hiciésemos hoy.`}</p>
    <p>{`En las discusiones teóricas, hay cuestiones difíciles de esclarecer acerca de cuánto debería estar dispuesta a sacrificar una generación en aras del futuro. Las `}<a parentName="p" {...{
        "href": "https://globalprioritiesinstitute.org/hilary-greaves-william-macaskill-the-case-for-strong-longtermism-2/"
      }}>{`versiones radicales`}</a>{` del largoplacismo podrían defender que tenemos la abrumadora obligación moral de salvaguardar el futuro, ya que hay mucho en juego y no hay un límite en cuanto a lo que se nos podría exigir. Si esto es cierto, lo correcto sería hacer sacrificios como individuos y como sociedad. No obstante, el largoplacismo admite perfectamente que se fijen límites estrictos a lo que es razonable pedir a una generación.`}</p>
    <p>{`Por suerte, este es un debate principalmente académico, ya que el mundo apenas está invirtiendo recursos en intentar proteger y mejorar el futuro a largo plazo de manera directa. Es cierto que dedicamos parte de nuestros recursos a proyectos que podrían mejorar el futuro de forma secundaria, como es el caso de las iniciativas generales para mitigar los efectos del cambio climático. No obstante, en términos globales, en la actualidad se asignan muy pocos recursos a reflexionar y actuar directamente con miras a influir de forma positiva en el futuro a largo plazo. Así las cosas, no hay un consenso entre los largoplacistas acerca de cuánto gasto deberíamos destinar, aunque todos están de acuerdo en que deberíamos invertir y hacer mucho más.`}</p>
    <p>{`Durante aproximadamente los últimos dos años, se han dedicado unos 200 millones de dólares al año a áreas de trabajo largoplacistas, y distintos filántropos que están comprometidos con las ideas del largoplacismo han donado unos 20 000 millones de dólares (`}<a parentName="p" {...{
        "href": "https://80000hours.org/2021/07/effective-altruism-growing/"
      }}>{`este artículo`}</a>{` ofrece un buen resumen de la financiación). Esto significa que menos de un 0,001 % del producto mundial bruto (es decir, los ingresos combinados de todos los países del mundo) se destina expresamente a proteger el destino a largo plazo de la humanidad. Dicho de otro modo, se ha invertido menos de la mitad de los `}<a parentName="p" {...{
        "href": "https://www.forbes.com/athletes/list/"
      }}>{`ingresos combinados`}</a>{` de los diez deportistas mejor pagados del planeta, menos del 0,2 % de los ingresos de la `}<a parentName="p" {...{
        "href": "https://www.researchandmarkets.com/reports/5406472/north-american-gaming-almanac-2020-2021-edition?utm_source=dynamic&utm_medium=GNOM&utm_code=6lw7zg&utm_campaign=1347085+-+North+America+Gaming+Almanac+2019-2020+-+United+States%27+Gaming+Revenue+Grew+3.42%25%2c+to+Total+%24109.7+Billion&utm_exec=joca220gnomd"
      }}>{`industria de los casinos y las apuestas`}</a>{` de EE. UU. y menos del 5 % de la cantidad que se gasta anualmente en `}<a parentName="p" {...{
        "href": "https://www.statista.com/statistics/190426/top-ice-cream-brands-in-the-united-states/"
      }}>{`helados`}</a>{` en EE. UU.`}</p>
    <p>{`Es importante también analizar con honestidad en qué sentido y hasta qué punto el largoplacismo podría implicar que debemos hacer sacrificios en el presente por el bien de las generaciones futuras. Si creemos que algo es una prioridad moral clave de nuestro tiempo y que la sociedad no valora su importancia en su justa medida, es natural pensar que esto debería repercutir de forma notable en la manera en que destinamos recursos y en las cuestiones a las que prestamos atención. En consecuencia, el largoplacismo tiene una serie de implicaciones claras en cuanto a cómo debería destinar sus recursos la sociedad, qué políticas y normas son mejores, y cómo podemos marcar la diferencia a través de nuestra carrera profesional. En comparación con seguir actuando como hasta ahora, el largoplacismo podría proponer un sorprendente nivel de cautela y demora en el desarrollo de tecnologías potencialmente peligrosas, o podría implicar que destináramos una inversión asombrosamente grande a medidas de protección frente a catástrofes cuyos efectos pudieran perdurar en el futuro lejano. Por tanto, estaríamos mintiendo si dijéramos que el largoplacismo no nos exige nada. En este sentido, podemos decir que es exigente, pero no de forma desmesurada.`}</p>
    <p>{`Ya estamos familiarizados con la idea de que hacer sacrificios por otras personas suele ser lo correcto: no dudaríamos un segundo en echar a perder unos zapatos caros para salvar a un niño que se esté `}<a parentName="p" {...{
        "href": "https://www.thelifeyoucansave.org/child-in-the-pond/"
      }}>{`ahogando en un estanque`}</a>{` y estaríamos dispuestos a posponer una lucrativa carrera profesional para criar a nuestros hijos. Está claro que algunas cosas importan tanto que no nos duele hacer ciertos sacrificios. Si el largoplacismo está en lo correcto, la importancia del futuro a largo plazo es enorme. Por tanto, el largoplacismo nos exigiría que, si tuviéramos la oportunidad de influir positivamente en el futuro renunciando a algo en el corto plazo, lo hiciéramos. Este ejemplo puede parecer razonable, como también nos lo parece que la ética del sentido común nos exija que saltemos a un estanque para salvar a un niño que se está ahogando, aunque hacerlo nos destroce los lujosos zapatos que llevamos.`}</p>
    <p>{`Así las cosas, hay margen para debatir acerca de cuánto debería exigirnos el largoplacismo exactamente cuando sus exigencias entren en conflicto con otras prioridades morales. Parémonos a pensar en cómo se destinan los recursos filantrópicos: hay muchos problemas acuciantes en el mundo, pero los recursos de que disponemos son finitos. Si dependiera de nosotros decidir dónde se invierten esos recursos, la dificultad de la tarea nos abrumaría. En cierto sentido, cada dólar que se dedica a un área de trabajo largoplacista (como la bioseguridad) podría haberse destinado a erradicar la malaria o a mejorar el bienestar animal. Cuando elegimos donar a una causa concreta, podría decirse que estamos dejando de lado otra o, dicho de otro modo, que estamos haciendo un sacrificio. Desde luego, el largoplacismo no es la única postura moral que puede dictar qué debe priorizar una sociedad o cómo debemos invertir nuestro tiempo o nuestro dinero. Por ejemplo, también podríamos pensar que sacar de la pobreza a personas en el presente debería ser otra de las prioridades clave de nuestro tiempo. En este caso, no podemos contestar fácilmente a la pregunta de cuánto debería exigir el largoplacismo si lo comparamos con esa otra prioridad. A fin de cuentas, dar una respuesta definitiva a una cuestión de tal calibre queda fuera del alcance de unas humildes preguntas frecuentes.`}</p>
    <h2 {...{
      "id": "qué-tiene-que-ver-el-largoplacismo-con-el-altruismo-eficaz",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qu%C3%A9-tiene-que-ver-el-largoplacismo-con-el-altruismo-eficaz",
        "aria-label": "qué tiene que ver el largoplacismo con el altruismo eficaz permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Qué tiene que ver el largoplacismo con el altruismo eficaz?`}</h2>
    <p>{`El altruismo eficaz es un proyecto cuyo objetivo es usar la evidencia y la razón para avanzar en la resolución de los problemas más acuciantes del mundo, y que aboga por tomar medidas para paliarlos usando nuestro tiempo y nuestras donaciones con el fin de marcar una diferencia real. Se trata de un proyecto tanto intelectual como práctico. Una de las metas del altruismo eficaz es desarrollar un campo de investigación amplio que se centre en encontrar las mejores maneras de hacer el bien. Asimismo, busca poner en práctica los resultados de esa investigación e intenta impulsar cambios positivos reales.`}</p>
    <p>{`Se ha formado una enorme comunidad internacional en torno a este proyecto. Las personas inspiradas por el altruismo eficaz trabajan en distintas causas, desde mejorar el bienestar de los `}<a parentName="p" {...{
        "href": "https://animalcharityevaluators.org/"
      }}>{`animales`}</a>{` hasta `}<a parentName="p" {...{
        "href": "https://www.givewell.org/"
      }}>{`aliviar la pobreza y la salud mundiales`}</a>{`, pasando por reducir la probabilidad de que se produzcan catástrofes globales, como una `}<a parentName="p" {...{
        "href": "https://80000hours.org/problem-profiles/preventing-catastrophic-pandemics/"
      }}>{`pandemia`}</a>{` devastadora.`}</p>
    <p>{`Gran parte de lo que hoy conocemos por «largoplacismo» fue desarrollado por personas que eran y son parte de la comunidad del altruismo eficaz, si bien es cierto que no se les puede atribuir todo el mérito de haber creado la visión del mundo largoplacista. Otras personas dedicaron su tiempo a `}<a parentName="p" {...{
        "href": "https://longtermism.com/recursos%23historia"
      }}>{`concebir las ideas fundacionales`}</a>{` de esta postura moral mucho antes de que el altruismo eficaz existiera.`}</p>
    <p>{`Tanto el largoplacismo como el altruismo eficaz son movimientos intelectuales, es decir, conjuntos de ideas y cuestiones clave que pueden motivar distintas investigaciones en múltiples disciplinas y fomentar su puesta en práctica. En el altruismo eficaz, la comunidad es muy importante. Cada vez más personas de todo el mundo se identifican con sus ideas centrales y colaboran entre ellas para alcanzar objetivos comunes. En la actualidad, muchas personas que comparten las ideas del largoplacismo también dirían que son parte de la comunidad del altruismo eficaz. Sin embargo, esto no tiene por qué ser así para todo el mundo. Aunque tiene sentido que una persona a la que le interese el altruismo eficaz pueda acabar centrándose en el largoplacismo en particular, no hay ningún rasgo propio del largoplacismo por el cual sea necesario sentirse parte del altruismo eficaz para que nos importen sus ideas. En este sentido, que nos importe el altruismo eficaz tampoco implica que deba interesarnos el largoplacismo.`}</p>
    <p>{`De hecho, no está claro que el largoplacismo tenga que ser una «comunidad» o formar parte de una «identidad». Es posible que muchas personas acaben trabajando en problemas relacionados con el largoplacismo, pero no tendrían por qué sentirse parte de una comunidad largoplacista. A este respecto, podríamos comparar el largoplacismo con enfoques éticos más amplios, como los derechos humanos, o con paradigmas económicos, como el desarrollo sostenible. Estas ideas pueden ser sumamente importantes para investigadores, activistas y legisladores, pero estos no tienen por qué considerarse miembros de una «comunidad» concreta centrada en los derechos humanos o en el desarrollo sostenible.`}</p>
    <p>{`En resumen, no tenemos por qué identificarnos con el altruismo eficaz para avanzar en la investigación del largoplacismo ni para mejorar el futuro a muy largo plazo. En la práctica, muchas personas que trabajan en organizaciones largoplacistas clave no se identifican demasiado con el altruismo eficaz y tienen un bagaje social, espiritual y político de lo más diverso.`}</p>
    <h2 {...{
      "id": "qué-sentido-tiene-preocuparse-por-el-futuro-a-largo-plazo-si-nos-vamos-a-extinguir-de-todos-modos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qu%C3%A9-sentido-tiene-preocuparse-por-el-futuro-a-largo-plazo-si-nos-vamos-a-extinguir-de-todos-modos",
        "aria-label": "qué sentido tiene preocuparse por el futuro a largo plazo si nos vamos a extinguir de todos modos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Qué sentido tiene preocuparse por el futuro a largo plazo si nos vamos a extinguir de todos modos?`}</h2>
    <p>{`Algunas personas pueden pensar que la humanidad va camino de la extinción o de una decadencia de la que no podremos recuperarnos, y que nuestro fin llegará en un futuro no muy lejano. Si esto es cierto, es poco probable que el futuro a largo plazo tenga alguna importancia, ya que no quedará ningún humano para entonces. En consecuencia, no tendría mucho sentido pensar que mejorar ese futuro debería ser una prioridad moral.`}</p>
    <p>{`Sin embargo, esa creencia por sí sola no basta para concluir que el largoplacismo sea una postura equivocada, siempre y cuando podamos controlar si nos extinguimos o no. De hecho, es probable que este sea el caso: si los humanos somos el origen de los riesgos que amenazan nuestra supervivencia, deberíamos ser capaces de mitigarlos. Por tanto, en línea con lo que propone el largoplacismo, la conclusión que habría que extraer es que evitar nuestra extinción debería ser una prioridad moral, con el objetivo de legar un mundo en el que puedan prosperar miles de generaciones futuras.`}</p>
    <p>{`Para que la objeción de la pregunta inicial tenga sentido, deberíamos sostener dos cosas: que es probable que nos extingamos pronto y que no podemos hacer nada para evitarlo. Resulta muy difícil ver de qué manera ambas premisas podrían ser ciertas. Presumiblemente, si el riesgo de extinción humana es alto (y, por tanto, el número esperado de generaciones futuras es bajo), habría muchas medidas que podríamos tomar para reducir ese riesgo. Si, por contra, el riesgo es bajo, podría ser más complicado disminuirlo, pero la probabilidad de que nos extinguiéramos pronto sería muy baja y, por consiguiente, podríamos esperar una gran cantidad de generaciones futuras.`}</p>
    <p>{`Algunas personas pueden temer que el cambio climático vaya a provocar la extinción humana en los próximos siglos. No obstante, si bien es cierto que tendrá efectos devastadores en todo el mundo, un análisis equilibrado de las pruebas de que disponemos nos lleva a pensar que no es muy probable que nos extingamos como consecuencia directa del cambio climático (véase `}<a parentName="p" {...{
        "href": "https://thebreakthrough.org/issues/energy/flattening-the-curve-of-future-emissions"
      }}>{`este artículo`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.ipcc.ch/report/sixth-assessment-report-working-group-i/"
      }}>{`este informe`}</a>{` y `}<a parentName="p" {...{
        "href": "https://www.ipcc.ch/assessment-report/ar6/"
      }}>{`este otro informe`}</a>{`).`}</p>
    <p>{`Dicho esto, está claro que aplacar los efectos del cambio climático es tremendamente importante. De hecho, mitigar los daños causados por el cambio climático probablemente reduciría el riesgo de extinción global, ya que estos daños podrían ser un «factor de riesgo» (por ejemplo, podrían aumentar la probabilidad de un conflicto internacional). No obstante, parece que hay pocas probabilidades de que el cambio climático u otra tendencia que podamos prever vayan a provocar la extinción humana en un futuro cercano. En resumen, no pesa sobre nosotros una condena tan inminente como para que abandonemos la esperanza de sobrevivir y prosperar durante mucho tiempo.`}</p>
    <h2 {...{
      "id": "por-qué-nos-centramos-en-la-humanidad-qué-hay-de-los-animales-o-la-naturaleza",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#por-qu%C3%A9-nos-centramos-en-la-humanidad-qu%C3%A9-hay-de-los-animales-o-la-naturaleza",
        "aria-label": "por qué nos centramos en la humanidad qué hay de los animales o la naturaleza permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Por qué nos centramos en la humanidad? ¿Qué hay de los animales o la naturaleza?`}</h2>
    <p>{`El largoplacismo hace especial hincapié en los humanos: trata sobre el potencial de la humanidad y sobre las personas que vivirán en el futuro. Sin embargo, compartimos el planeta con miles de especies de animales no humanos que también importan moralmente, sobre todo si tenemos en cuenta que muchas de ellas tienen la capacidad de sufrir. Otros aspectos de la naturaleza también pueden tener una importancia intrínseca: quizá haya elementos del medioambiente que merezca la pena proteger y que no hayamos corrompido o trastocado los seres humanos. Entonces ¿por qué nos centramos tanto en la humanidad?`}</p>
    <p>{`La razón es que, para bien o para mal, los humanos nos encontramos en una posición en la que controlamos y somos responsables del destino de los animales y la naturaleza. Además, somos la única especie capaz de comprender los motivos morales para mejorar el devenir del futuro y actuar en consecuencia. Los animales e, incluso, ecosistemas enteros pueden ser pacientes morales (es decir, aquello de lo que debemos preocuparnos), pero los humanos somos los únicos agentes morales, gracias a nuestra capacidad de planificar qué acciones son mejores desde un punto de vista moral.`}</p>
    <p>{`Por tanto, el término «potencial humano» debería entenderse como «los futuros que podrían elegir los humanos» y no como «los futuros para la especie humana exclusivamente». El valor del futuro a largo plazo no tiene por qué determinarse simplemente en función del número de humanos que vivan en él. Es perfectamente razonable expresar una honda preocupación por mejorar el bienestar de los animales o por proteger aspectos de la naturaleza de cara al futuro. Esto se ve en la práctica: muchos largoplacistas son vegetarianos o veganos comprometidos.`}</p>
    <h2 {...{
      "id": "el-largoplacismo-no-se-basa-en-gran-medida-en-pequeñas-probabilidades-de-obtener-recompensas-enormes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#el-largoplacismo-no-se-basa-en-gran-medida-en-peque%C3%B1as-probabilidades-de-obtener-recompensas-enormes",
        "aria-label": "el largoplacismo no se basa en gran medida en pequeñas probabilidades de obtener recompensas enormes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿El largoplacismo no se basa en gran medida en pequeñas probabilidades de obtener recompensas enormes?`}</h2>
    <p>{`Una de las objeciones al largoplacismo es que se apoya en conjeturas extremadamente especulativas acerca de cómo influir en el futuro que tienen pocas probabilidades de rendir frutos, pero cuya importancia radica en la enorme magnitud de lo que está en juego. En este marco conceptual, incluso la más remota posibilidad de éxito hace que valga la pena dedicar recursos a esa conjeturas. Esto podría parecer «`}<a parentName="p" {...{
        "href": "https://globalprioritiesinstitute.org/on-two-arguments-for-fanaticism-jeff-sanford-russell-university-of-southern-california/"
      }}>{`fanático`}</a>{`» hasta el punto de ser inaceptable, sobre todo si las personas y los recursos que se dedican a proyectos largoplacistas pudieran destinarse a problemas mucho más tangibles y urgentes.`}</p>
    <p>{`Este problema es particularmente relevante en el caso de las iniciativas para mitigar los riesgos existenciales, es decir, aquellos que amenazan con limitar el potencial de la humanidad, ya sea a través de la extinción o de una situación igual de funesta de la que no podamos recuperarnos. Sabemos, por ejemplo, que las probabilidades de que un asteroide con la capacidad de llevarnos a la extinción colisione con la Tierra en este siglo son ínfimas, pero como destruiría el futuro de la humanidad por completo, quizá los largoplacistas sugieran invertir enormes cantidades de recursos en el desarrollo de un sistema de defensa contra asteroides. Este razonamiento suena un poco `}<a parentName="p" {...{
        "href": "https://nickbostrom.com/papers/pascal.pdf"
      }}>{`sospechoso`}</a>{`.`}</p>
    <p>{`Es cierto que el razonamiento largoplacista se basa necesariamente y a un nivel inusual en hacer extrapolaciones a partir de datos incompletos (usando métodos de pronosticación creativos) y en asumir un alto grado de incertidumbre. Lo que no es cierto es que el largoplacismo derive su importancia de multiplicar el enorme valor de los distintos futuros posibles por una serie de probabilidades ínfimas.`}</p>
    <p>{`Analicemos el argumento largoplacista para evitar riesgos catastróficos. No se basa en que, aunque los riesgos sean insignificantes, la «recompensa» que estaríamos desperdiciando si no los mitigamos sea proporcionalmente mayor. Más bien, el problema radica en que muchos de los riesgos parecen inaceptable e insosteniblemente altos. Asimismo, parece posible reducirlos en gran medida, no en una proporción minúscula. De hecho, apenas hace falta apelar a las generaciones futuras para entender por qué deberíamos dedicar más esfuerzos a atajar estos riesgos, como explica el investigador Carl Shulman en este `}<a parentName="p" {...{
        "href": "https://80000hours.org/podcast/episodes/carl-shulman-common-sense-case-existential-risks/"
      }}>{`episodio de pódcast`}</a>{`.`}</p>
    <p>{`Una implicación particularmente preocupante de elegir pequeñas probabilidades con enormes recompensas podría ser que renunciásemos por completo a beneficios casi seguros o, incluso, que hubiera una probabilidad alta de provocar un daño menor. Sin embargo, este tampoco es el caso del largoplacismo. De hecho, las intervenciones que propone el largoplacismo también parecen adecuadas en el corto plazo y es probable que tengan beneficios importantes incluso en el caso de que no acaben evitando una catástrofe existencial. Por ejemplo, la mayoría de las cosas que podemos hacer para mitigar el riesgo de una pandemia devastadora también podrían venirnos bien para paliar pandemias menos graves. Esto también se aplica a los riesgos derivados del cambio climático extremo, de un conflicto nuclear y de la IA potente. El trabajo que se realice en esos campos seguramente resulte útil en un plazo relativamente corto aunque los peores escenarios imaginados no lleguen a ocurrir.`}</p>
    <p>{`No es posible evitar la incertidumbre acerca de cómo será el futuro, pero hay acciones que podemos realizar en el presente que parecen realmente buenas para el futuro a largo plazo, como reducir la probabilidad de que se produzca una guerra entre grandes potencias en este siglo o poner en marcha medidas contra las pandemias artificiales.`}</p>
    <h2 {...{
      "id": "podría-justificar-el-largoplacismo-regímenes-políticos-perniciosos-como-el-totalitarismo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#podr%C3%ADa-justificar-el-largoplacismo-reg%C3%ADmenes-pol%C3%ADticos-perniciosos-como-el-totalitarismo",
        "aria-label": "podría justificar el largoplacismo regímenes políticos perniciosos como el totalitarismo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`¿Podría justificar el largoplacismo regímenes políticos perniciosos, como el totalitarismo?`}</h2>
    <p>{`Un rasgo clave del largoplacismo es que reconoce lo mucho que está en juego (el valor del futuro a largo plazo podría ser inmenso) y advierte de que pende de un hilo: las acciones que tomemos en el presente. Teniendo esto en cuenta, ¿qué sucedería entonces si todo apuntara a que la mejor o la única manera de asegurarnos de que ese futuro saliera bien fuera implantar un régimen político extremadamente objetable o infligir un daño grave en el corto plazo?`}</p>
    <p>{`Pongamos un ejemplo concreto. Imaginemos que, en el siglo actual, desarrollamos una tecnología tan peligrosa y barata que medidas draconianas como la vigilancia masiva parecen ser la única opción para evitar una catástrofe (véase Bostrom `}{`[`}{`2019`}{`]`}{`, «`}<a parentName="p" {...{
        "href": "https://nickbostrom.com/papers/vulnerable.pdf"
      }}>{`The Vulnerable World Hypothesis`}</a>{`» `}{`[`}{`La hipótesis del mundo vulnerable`}{`]`}{`). En este mundo imaginario, la biotecnología ha avanzado tanto que un estudiante de secundaria espabilado puede crear y liberar un patógeno altamente infeccioso. Para evitar que multitud de pandemias catastróficas asolen el mundo, las sociedades podrían decidir implantar medidas de vigilancia intrusiva y mecanismos para asegurarse de que se aplican. El énfasis que hace el largoplacismo en la magnitud de lo que está en juego podría aumentar la probabilidad de que esas medidas parecieran el camino correcto. Este razonamiento es especialmente preocupante, sobre todo porque guarda mucha similitud con las justificaciones históricas del totalitarismo y de las atrocidades que cometen los regímenes totalitarios.`}</p>
    <p>{`El filósofo liberal Isaiah Berlin `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/1997/11/07/arts/isaiah-berlin-philosopher-and-pluralist-is-dead-at-88.html"
      }}>{`resumió`}</a>{` esta clase de argumento:`}</p>
    <p>{`«Para lograr que la humanidad sea justa, feliz, creativa y armónica para siempre, ¿qué precio podría ser demasiado alto con tal de conseguirlo? Para hacer esa tortilla, no hay límite en el número de huevos que deberíamos romper».`}</p>
    <p>{`Es normal que, si leemos citas de obras acerca del largoplacismo fuera de contexto, nos generen cierta intranquilidad. Por tanto, puede entenderse la preocupación de que el largoplacismo podría justificar actitudes políticas perniciosas de gran calado. Sin embargo, francamente, resulta muy difícil ver la relación que guarda esa preocupación con el trabajo y los intereses de los largoplacistas en la actualidad.`}</p>
    <p>{`De hecho, el totalitarismo preocupa especialmente a los largoplacistas, ya que un régimen totalitario que se sirva de nuevas tecnologías de vigilancia y aplicación de la ley podría ser precisamente uno de los tipos de riesgos existenciales que los largoplacistas pretenden evitar. Por esta razón, gran parte del debate y de las acciones que propone el largoplacismo se centra en promulgar normas que han soportado el paso del tiempo; por ejemplo, el antiautoritarismo, el liberalismo y la idea de una sociedad abierta.`}</p>
    <p>{`Los regímenes totalitarios del pasado fueron responsables de las atrocidades más abyectas de la historia de la humanidad: calamidades y muertes provocadas no por la naturaleza, sino por decisiones humanas. Esos regímenes fracasaron con terribles consecuencias no solo porque estuvieran más que dispuestos a hacer sacrificios por un futuro mejor, sino porque actuaron con la creencia absolutamente equivocada de que la violencia revolucionaria y el totalitarismo conseguirían hacer del mundo un lugar mejor a corto o largo plazo. Por tanto, resulta muy difícil imaginar una situación realista en la que la perspectiva largoplacista recomendase usar medidas de represión o violencia masivas que otras perspectivas razonables no recomendaran también (por ejemplo, declarar la guerra a las potencias del Eje en la Segunda Guerra Mundial).`}</p>
    <p>{`Dicho esto, sería un error ignorar la preocupación acerca de la posibilidad de que las ideas largoplacistas pudieran tergiversarse o malinterpretarse para justificar actitudes políticas perniciosas en el futuro. Sabemos que incluso las aspiraciones más nobles pueden acabar teniendo consecuencias terribles en manos de personas normales, que pueden cometer errores. Si esa preocupación fuera legítima, deberíamos asegurarnos de comunicar las ideas largoplacistas de manera sensible y sincera, y evitar que se pervirtiera su uso o que se aplicaran peligrosamente.`}</p>
    <h2 {...{
      "id": "los-largoplacistas-no-prestan-tanta-atención-al-cambio-climático-como-esperaba-por-qué",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#los-largoplacistas-no-prestan-tanta-atenci%C3%B3n-al-cambio-clim%C3%A1tico-como-esperaba-por-qu%C3%A9",
        "aria-label": "los largoplacistas no prestan tanta atención al cambio climático como esperaba por qué permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Los largoplacistas no prestan tanta atención al cambio climático como esperaba. ¿Por qué?`}</h2>
    <p>{`Cuando pensamos en formas de mejorar el futuro a largo plazo, a muchas personas se nos viene a la cabeza inmediatamente el cambio climático. Sabemos más allá de toda duda razonable que la actividad humana altera el clima de la Tierra y que el cambio climático tendrá efectos devastadores, como fenómenos meteorológicos más extremos, desplazamientos masivos de poblaciones (predominantemente empobrecidas) y pérdida de biodiversidad. Algunos de esos efectos podrían prolongarse durante mucho tiempo, ya que los gases de efecto invernadero pueden permanecer en la atmósfera terrestre durante decenas de miles de años. Dicho esto, cabe señalar que podemos controlar cuánto daño causamos; por ejemplo, redoblando nuestros esfuerzos para desarrollar tecnologías verdes, creando más métodos para aprovechar fuentes de energía con cero emisiones y poniendo precio a las emisiones de carbono en consonancia con su verdadero coste social. Por estas razones, los largoplacistas tienen motivos de peso para preocuparse por el cambio climático, y muchos de ellos están trabajando en cuestiones relacionadas con este problema (véase `}<a parentName="p" {...{
        "href": "https://www.cser.ac.uk/resources/assessing-climate-changes-contribution-global-catastrophic-risk/"
      }}>{`este informe`}</a>{`). A pesar de ello, los largoplacistas en general parecen centrarse relativamente más en otros problemas, muchos de los cuales son bastante más difíciles de comprender. ¿Cuál es el motivo?`}</p>
    <p>{`Una de las preocupaciones más prioritarias de los largoplacistas es la posibilidad de una catástrofe existencial, es decir, un suceso que destruya irrevocablemente el potencial a largo plazo de la humanidad. En ocasiones se define el cambio climático como una «amenaza existencial», pero esta denominación pone el listón demasiado alto. Según esta definición, no queda claro si el cambio climático está entre las causas más plausibles de una catástrofe existencial. El filósofo Toby Ord, basándose en los mejores datos disponibles en el momento, calculó que la probabilidad de que el cambio climático produzca directamente una catástrofe existencial en el próximo siglo es de aproximadamente 1 entre 1000 (y radica principalmente en la posibilidad de que se den situaciones extremas y descontroladas). Por contra, el riesgo estimado de las pandemias artificiales es 30 veces más alto y el de la IA no alineada es, de manera muy orientativa, 100 veces mayor, según Ord. Dicho esto, no cabe duda de que el cambio climático es una emergencia global. Sin embargo, si nos basamos en la información que tenemos acerca del tema, parece poco probable que provoque la extinción humana.`}</p>
    <p>{`Por tanto, aunque muchos defensores del clima se refieran al cambio climático como una «amenaza existencial», este no encaja con la definición de «amenaza existencial» que emplean los largoplacistas. Visto de esta manera, lo que parece un desacuerdo considerable acerca de la gravedad de los efectos del cambio climático podría deberse en parte a que los términos en cuestión se usan de manera diferente.`}</p>
    <p>{`Otro motivo por el que algunos largoplacistas dan prioridad a otras amenazas para nuestro futuro que no son el cambio climático es que, por suerte, los esfuerzos para mitigarlo reciben gran cantidad de recursos y atención pública. Esto significa que, en comparación, el cambio climático está menos desatendido que, por ejemplo, la reducción de riesgos derivados de las armas nucleares, los patógenos genéticamente modificados o la IA. Este aspecto es importante porque cuantos más recursos se destinan a resolver un problema, menos impacto tienden a tener los recursos adicionales. En consecuencia, es probable que queden menos opciones al alcance de la mano para avanzar en la mitigación de los efectos del cambio climático en comparación con otros problemas más desatendidos. Cada año se invierte aproximadamente `}<a parentName="p" {...{
        "href": "https://news.un.org/en/story/2021/06/1094762"
      }}>{`un billón de dólares`}</a>{` en tecnología verde y en otras estrategias de mitigación. Las organizaciones sin ánimo de lucro dedican en torno a `}<a parentName="p" {...{
        "href": "https://philanthropynewsdigest.org/news/climate-change-mitigation-funding-grew-in-2020-but-still-insufficient"
      }}>{`10 000 millones de dólares al año`}</a>{` a esta causa. En contraposición, analicemos el riesgo de pandemias peores que la de covid-19. La covid-19 ha provocado más de 10 millones de `}<a parentName="p" {...{
        "href": "https://ourworldindata.org/covid-deaths"
      }}>{`muertes`}</a>{` prematuras y sus `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Economic_impact_of_the_COVID-19_pandemic"
      }}>{`estragos económicos`}</a>{` han supuesto pérdidas de billones de dólares, pero `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/nws5pai9AB6dCQqxq/how-are-resources-in-ea-allocated-across-issues"
      }}>{`menos de 100 millones de dólares`}</a>{` procedentes de los fondos de organizaciones sin ánimo de lucro se destinan a mejorar nuestra preparación frente a pandemias (datos del 2019). Por consiguiente, parece que un científico experto más o una donación adicional pueden tener un impacto mayor si se dedican a la preparación ante pandemias que si se destinan a mitigar los efectos generales del cambio climático.`}</p>
    <p>{`En un mundo ideal, habría razones de sobra para invertir mucho más de lo que estamos invirtiendo ahora en soluciones prácticas a los problemas relacionados con el cambio climático. Por desgracia, la realidad es que nuestros recursos son finitos, por lo que, si queremos hacer el mayor bien posible con los medios que tenemos, debemos identificar los problemas que parecen más acuciantes y ocuparnos de ellos, aunque vaya en detrimento de otros que también son importantes.`}</p>
    <p>{`Dicho esto, hay maneras de luchar contra el cambio climático que parecen estar particularmente desatendidas desde un punto de vista largoplacista. Se está debatiendo acerca de la energía nuclear y de cómo asegurarnos de que, si usamos la ingeniería climática (o geoingeniería), lo hacemos de forma segura y responsable. Otro ejemplo clave de desatención en este ámbito es la modelización de los peores desenlaces posibles, como los efectos de un cambio climático descontrolado. Estas situaciones podrían provocar daños que perduraran en el futuro lejano y de los que, casi con total seguridad, no podríamos recuperarnos. Esta modelización también podría ayudarnos a estudiar y mitigar otros riesgos, como los efectos climáticos provocados por una guerra nuclear a gran escala, la erupción de un supervolcán o el impacto de un asteroide de gran tamaño.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      